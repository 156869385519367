import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
function addSpacesBeforeCapitals(str) {
    return str.replace(/([A-Z])/g, ' $1').trim();
}

export default {
    state: {
        HasUrlParams: false,
        Keyword: null,
        Specialties: null,
        ProviderName: null,
        Location: null,
        Distance: 30,
        Insurances: null,
        showInsurancesBlock: false,
        showTravelTime: false,
        AcceptingNewPatients: null,
        OffersOnlineScheduling: null,
        OffersVirtualVisits: null,
        NextAvailability: null,
        PreferredDays: null,
        PreferredTimes: [],
        Genders: [],
        Languages: [],
        Latitude: null,
        Longitude: null,
        LocationName: null,
        Page: 1,
        PageSize: 10,
        Sort: 'relevance,networks,availability_soonest',
        Seed: 'christus',
        fetchingAppointments: false,
        Appointments: [],
        ProvidersByLocation: [],
        ClusterLabel: null,
        locationsLoading: false,
        ActiveFilters: {},
        resultsLoading: false,
        results: {
            providers: []
        },
        resultsLabel: '',
        filterRemoved: false,
        distanceRemoved: false,
        clearAll: false,
        mobileFilterActive: false,
        firstLoad: true
    },
    mutations: {
        HasUrlParams: (state, HasUrlParams) => { state.HasUrlParams = HasUrlParams },
        Keyword: (state, Keyword) => { state.Keyword = Keyword },
        KeywordCategory: (state, KeywordCategory) => { state.KeywordCategory = KeywordCategory },
        Specialties: (state, Specialties) => { state.Specialties = Specialties },
        ProviderName: (state, ProviderName) => { state.ProviderName = ProviderName },
        Location: (state, Location) => { state.Location = Location },
        Distance: (state, Distance) => { state.Distance = Distance },
        Insurances: (state, Insurances) => { state.Insurances = Insurances },
        showInsurancesBlock: (state, showInsurancesBlock) => { state.showInsurancesBlock = showInsurancesBlock },
        showTravelTime: (state, showTravelTime) => { state.showTravelTime = showTravelTime },
        AcceptingNewPatients: (state, AcceptingNewPatients) => { state.AcceptingNewPatients = AcceptingNewPatients },
        OffersOnlineScheduling: (state, OffersOnlineScheduling) => { state.OffersOnlineScheduling = OffersOnlineScheduling },
        OffersVirtualVisits: (state, OffersVirtualVisits) => { state.OffersVirtualVisits = OffersVirtualVisits },
        NextAvailability: (state, NextAvailability) => { state.NextAvailability = NextAvailability },
        PreferredDays: (state, PreferredDays) => { state.PreferredDays = PreferredDays },
        PreferredTimes: (state, PreferredTimes) => { state.PreferredTimes = PreferredTimes },
        Genders: (state, Genders) => { state.Genders = Genders },
        Languages: (state, Languages) => { state.Languages = Languages },
        Latitude: (state, Latitude) => { state.Latitude = Latitude },
        Longitude: (state, Longitude) => { state.Longitude = Longitude },
        LocationName: (state, LocationName) => { state.LocationName = LocationName },
        Page: (state, Page) => { state.Page = Page },
        PageSize: (state, PageSize) => { state.PageSize = PageSize },
        Sort: (state, Sort) => { state.Sort = Sort },
        Seed: (state, Seed) => { state.Seed = Seed },
        fetchingAppointments: (state, fetchingAppointments) => { state.fetchingAppointments = fetchingAppointments },
        Appointments: (state, Appointments) => { state.Appointments = Appointments },
        ProvidersByLocation: (state, ProvidersByLocation) => { state.ProvidersByLocation = ProvidersByLocation },
        ClusterLabel: (state, ClusterLabel) => { state.ClusterLabel = ClusterLabel },
        addOrUpdateFilter(state, { key, value }) {Vue.set(state.ActiveFilters, key, value);},
        removeFilter(state, key) {Vue.delete(state.ActiveFilters, key);},
        removeArrayElement(state, { key, index }) {
            if (Array.isArray(state.ActiveFilters[key])) {
                state.ActiveFilters[key].splice(index, 1);
            }
        },
        removeAllFilters(state) {
            for (const key in state.ActiveFilters) {
                if (state.ActiveFilters.hasOwnProperty(key)) {
                    Vue.delete(state.ActiveFilters, key);
                }
            }
        },
        resultsLoading: (state, resultsLoading) => { state.resultsLoading = resultsLoading },
        locationsLoading: (state, locationsLoading) => { state.locationsLoading = locationsLoading },
        results: (state, results) => { state.results = results },
        resultsLabel: (state, resultsLabel) => { state.resultsLabel = resultsLabel },
        filterRemoved: (state, filterRemoved) => { state.filterRemoved = filterRemoved },
        distanceRemoved: (state, distanceRemoved) => { state.distanceRemoved = distanceRemoved },
        keywordRemoved: (state, keywordRemoved) => { state.keywordRemoved = keywordRemoved },
        clearAll: (state, clearAll) => { state.clearAll = clearAll },
        mobileFilterActive: (state, mobileFilterActive) => { state.mobileFilterActive = mobileFilterActive },
        firstLoad: (state, firstLoad) => { state.firstLoad = firstLoad }
    },
    getters: {
        HasUrlParams: (state) => state.HasUrlParams,
        Keyword: (state) => state.Keyword,
        KeywordCategory: (state) => state.KeywordCategory,
        Specialties: (state) => state.Specialties,
        ProviderName: (state) => state.ProviderName,
        Location: (state) => state.Location,
        Distance: (state) => state.Distance,
        Insurances: (state) => state.Insurances,
        showInsurancesBlock: (state) => state.showInsurancesBlock,
        showTravelTime: (state) => state.showTravelTime,
        AcceptingNewPatients: (state) => state.AcceptingNewPatients,
        OffersOnlineScheduling: (state) => state.OffersOnlineScheduling,
        OffersVirtualVisits: (state) => state.OffersVirtualVisits,
        NextAvailability: (state) => state.NextAvailability,
        PreferredDays: (state) => state.PreferredDays,
        PreferredTimes: (state) => state.PreferredTimes,
        Genders: (state) => state.Genders,
        Languages: (state) => state.Languages,
        Latitude: (state) => state.Latitude,
        Longitude: (state) => state.Longitude,
        LocationName: (state) => state.LocationName,
        Page: (state) => state.Page,
        PageSize: (state) => state.PageSize,
        Sort: (state) => state.Sort,
        Seed: (state) => state.Seed,
        fetchingAppointments: (state) => state.fetchingAppointments,
        Appointments: (state) => state.Appointments,
        ProvidersByLocation: (state) => state.ProvidersByLocation,
        ClusterLabel: (state) => state.ClusterLabel,
        ActiveFilters: (state) => state.ActiveFilters,
        resultsLoading: (state) => state.resultsLoading,
        locationsLoading: (state) => state.locationsLoading,
        results: (state) => state.results,
        resultsLabel: (state) => state.resultsLabel,
        filterRemoved: (state) => state.filterRemoved,
        distanceRemoved: (state) => state.distanceRemoved,
        keywordRemoved: (state) => state.keywordRemoved,
        clearAll: (state) => state.clearAll,
        mobileFilterActive: (state) => state.mobileFilterActive,
        firstLoad: (state) => state.firstLoad,
        getFilterValue: (state) => (key) => {
            const value = state.ActiveFilters[key];
            if (Array.isArray(value)) {
              return value.map((item) =>
                typeof item === 'boolean'
                  ? item.toString() === 'true'
                    ? addSpacesBeforeCapitals(key)
                    : 'Not ' + addSpacesBeforeCapitals(key)
                  : item
              );
            } else {
              return typeof value === 'boolean'
                ? value.toString() === 'true'
                  ? addSpacesBeforeCapitals(key)
                  : 'Not ' + addSpacesBeforeCapitals(key)
                : value;
            }
        }
    },
    actions: {
        async fetchResults({ state, commit, rootState, dispatch }, payload) {
            if(!rootState.banner_only){
                commit('resultsLoading', true);

                const currentPage = payload && payload.pageOnly ? state.Page : 1;
                commit("Page", currentPage);

                const urlParams = new URLSearchParams(window.location.search);
                if(urlParams.get('zip') && !state.Latitude && !state.Longitude){
                    return;
                }

                if(!state.Location && (state.Latitude && state.Longitude)){
                    commit("Location", `${state.Latitude},${state.Longitude}`);
                }

                dispatch('fetchLocationCookie');
                
                const data = {
                    Keyword: state.Keyword ? state.Keyword : null,
                    SpecialtiesFilter: state.Specialties ? state.Specialties : null,
                    ProviderName: state.ProviderName ? state.ProviderName : null,
                    Distance: state.Latitude ? state.Distance : null,
                    Insurance: state.Insurances,
                    AcceptingNewPatients: state.AcceptingNewPatients,
                    OffersOnlineScheduling: state.OffersOnlineScheduling,
                    OffersVirtualVisits: state.OffersVirtualVisits,
                    NextAvailability: state.NextAvailability,
                    PreferredDays: state.PreferredDays,
                    PreferredTimes: state.PreferredTimes,
                    Genders: state.Genders,
                    Languages: state.Languages,
                    Latitude: state.Latitude,
                    Location: state.Location ? state.Location : null,
                    Longitude: state.Longitude,
                    Page: currentPage,
                    PageSize: state.PageSize,
                    Sort: state.Sort,
                    Seed: state.Seed,
                };

                if(state.KeywordCategory){
                    data[state.KeywordCategory] = data.Keyword;
                }

                Object.keys(data).forEach(key => {
                    if (!data[key]) {
                        delete data[key];
                    }
                });

                setTimeout(() => {
                    dispatch('setUrlParams', data);
                }, 1000);

                const url = new URL(`${rootState.api_url}/api/Kyruus/Search`);
                const requestOpts = {
                    method: "POST", 
                    headers: { 
                        'content-type': 'application/json; charset=utf-8'
                    },
                    body: JSON.stringify(data)
                }
                const response = await fetch(url, requestOpts)
                    .then(response => response.json());
                
                commit('results', response);
                commit('resultsLoading', false);
                dispatch('fetchAppointments');
                commit('firstLoad', false);
            }
        },
        async fetchAppointments({ state, commit, rootState }) {
            commit('fetchingAppointments', true);
            let epicIds = state.results.providers
                .filter(x => x.epic_id && x.epic_id.trim())
                .map(x => x.epic_id);
            if(!epicIds || epicIds.length == 0){ 
                commit('Appointments', []);
                commit('fetchingAppointments', false);
                return; 
            }
            const url = new URL(`${rootState.api_url}/api/doctorlocations/getprovidersappointmentdataenhanced?ids=${epicIds.join("|")}`);
            const requestOpts = {
                method: "GET", 
                headers: { 
                    'content-type': 'application/json; charset=utf-8'
                }
            }
            const response = await fetch(url, requestOpts)
                .then(response => response.json());

            commit('Appointments', response);
            setTimeout(() => {
                commit('fetchingAppointments', false);
            }, 500);
        },
        async fetchProvidersbyLocations({ commit, rootState }, locations) {
            commit('locationsLoading', true);
            const url = new URL(`${rootState.api_url}/api/Kyruus/Locations?ids=${locations}`);
            const requestOpts = {
                method: "POST", 
                headers: { 
                    'content-type': 'application/json; charset=utf-8'
                }
            }
            const response = await fetch(url, requestOpts)
                .then(response => response.json());

            commit('ProvidersByLocation', response);
            commit('locationsLoading', false);
        },
        async fetchClusterCount({ rootState },locations){
            const url = new URL(`${rootState.api_url}/api/Kyruus/Locations?ids=${locations}`);
            const requestOpts = {
                method: "POST", 
                headers: { 
                    'content-type': 'application/json; charset=utf-8'
                }
            }
            try {
                const response = await fetch(url, requestOpts);
            
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            
                const data = await response.json();
                return data; // Directly return the data
            } catch (error) {
                console.error('Error during fetch:', error);
                throw error; // Propagate the error if needed
            }
        },
        removeArrayElement({ commit }, { key, index }) {
            commit('removeArrayElement', { key, index });
        },
        getParamsFromUrl({ state, commit, rootState, dispatch }){
            return new Promise((resolve, reject) => {
                if(rootState.banner_only){resolve(true);}
                else{

                    const urlParams = new URLSearchParams(window.location.search);
                    let keywordCategory = '';
                    let keyword = '';
                    if(urlParams.get('conditions_symptoms')){ keywordCategory = 'conditions_symptoms'; keyword = urlParams.get('conditions_symptoms'); }
                    if(urlParams.get('location_name')){ keywordCategory = 'location_name'; keyword = urlParams.get('location_name'); }
                    if(urlParams.get('provider_name')){ keywordCategory = 'provider_name'; keyword = urlParams.get('provider_name'); }
                    if(urlParams.get('ProviderName')){ 
                        keywordCategory = 'provider_name'; keyword = urlParams.get('ProviderName'); 
                    }
                    if(urlParams.get('primary_care')){ keywordCategory = 'primary_care'; keyword = urlParams.get('primary_care'); }
                    if(urlParams.get('specialties')){ keywordCategory = 'specialties'; keyword = urlParams.get('specialties'); }
                    if(urlParams.get('specialty')){ 
                        keywordCategory = 'specialties'; keyword = urlParams.get('specialty'); 
                        //commit("Specialties", keyword);
                    }

                    // If Primary Care provided as a specialty, use the Kyruus primary_care param instead
                    if(keywordCategory == 'specialties' && keyword && keyword.toLowerCase() == 'primary care'){ keywordCategory = 'primary_care'; keyword = 'Primary Care Providers'; }

                    if(urlParams.get('treatments_procedures')){ keywordCategory = 'treatments_procedures'; keyword = urlParams.get('treatments_procedures'); }
                    if(keywordCategory == '' && urlParams.get('Keyword')){ keyword = urlParams.get('Keyword'); }

                    history.replaceState(null, null, '?');

                    if(keyword){
                        commit("Keyword", keyword);
                        if(keywordCategory){
                            commit("KeywordCategory", keywordCategory);
                        }
        
                    }

                    if(urlParams.get('Location')){
                        let location = urlParams.get('Location');
                        commit("Location", location);
                        commit("Latitude", location.split(',')[0]);
                        commit("Longitude", location.split(',')[1]);
                        commit("Sort", 'networks,distance');
                    }

                    resolve(true);
                }
            });
        },
        setUrlParams({ state, commit, rootState }, data){
            return new Promise((resolve, reject) => {
                if(rootState.banner_only){resolve(true);}
                else{
                    const urlParams = data ? new URLSearchParams(window.location.search) : new URLSearchParams();
                    const ignoreKeys = ['Seed'];
                    for (const key in data) {
                        if(ignoreKeys.indexOf(key) === -1){
                            let value = data[key];
                            if(value && value != null && value.length > 0){
                                urlParams.set(key, value);
                            }
                            else{
                                urlParams.delete(key);
                            }
                        }
                    }

                    history.replaceState(null, null, "?"+urlParams.toString());
                    resolve(true);
                }
            });
        },
        setLocationCookie(rootState, {name,lat, lng, isCurrentLocation}){
            const cookieVal = JSON.stringify({
                name: name,
                location: {
                  lat: lat,
                  lng: lng
                },
                isCurrentLocation: isCurrentLocation
            });

            VueCookies.set('christus-preferred-location', cookieVal);
        },
        removeLocationCookie(){
            VueCookies.remove('christus-preferred-location');
        },
        fetchLocationCookie({commit}){
            const cookieValue = VueCookies.get('christus-preferred-location');
            if (cookieValue) {
                commit("LocationName", cookieValue.name);
                commit("Latitude", cookieValue.location.lat);
                commit("Longitude", cookieValue.location.lng);
                commit("Location", `${cookieValue.location.lat},${cookieValue.location.lng}`);
            }
        },
    }
}