import "core-js/stable";
import "regenerator-runtime/runtime";
import "isomorphic-fetch";
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import vueCustomElement from 'vue-custom-element'
import Config from './config/config';
import './scss/main.scss';
import 'slick-carousel';

Vue.config.productionTip = false

Config.load(`${process.env.NODE_ENV}`, '../config').then((config) => {
    if(!process.env.VUE_APP_API_URL){
      store.state.api_url = config.api_url ? config.api_url : window.location.origin;
    }
    else{
      store.state.api_url = process.env.VUE_APP_API_URL;
    }
    Vue.use(VueGoogleMaps, {
        load: {
            key: config.google_maps_api_key,
            libraries: 'places', // This is required if you use the Autocomplete plugin
            // OR: libraries: 'places,drawing'
            // OR: libraries: 'places,drawing,visualization'
            // (as you require)
    
            //// If you want to set the version, you can do so:
            // v: '3.26',
        },
    
        //// If you intend to programmatically custom event listener code
        //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
        //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
        //// you might need to turn this on.
        // autobindAllEvents: false,
    
        //// If you want to manually install components, e.g.
        //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
        //// Vue.component('GmapMarker', GmapMarker)
        //// then set installComponents to 'false'.
        //// If you want to automatically install all the components this property must be set to 'true':
        installComponents: true
    });
    
    // Version for browsers that can't support web components
    (function (el){
        if(el){
          new Vue({
            store,
            el: el,
            template: '<App :title=title :search-banner-heading=searchBannerHeading :search-banner-body=searchBannerBody :no-result-content=noResultContent :post-filter-content=postFilterContent :visit-callout-content=visitCalloutContent :visit-callout-btn=visitCalloutBtn :visit-callout-link=visitCalloutLink ></App>',
            components: {App},
            data: {
              appViewMode: '',
              enableMapFeatures: false,
              postFilterContent: '',
              visitCalloutContent: '',
              visitCalloutBtn: '',
              visitCalloutLink: '',
              noResultContent: '',
              searchBannerHeading: '',
              searchBannerBody: '',
              searchNoResultsText: '',
              searchUrl: '',
              title: ''
            },
            created: function(){
              this.bannerOnly = el.attributes['data-banner-only'] ? el.attributes['data-banner-only'].value == 'true' : this.bannerOnly;
              this.bannerHomepage = el.attributes['data-banner-homepage'] ? el.attributes['data-banner-homepage'].value == 'true' : this.bannerHomepage;
              this.$store.commit('banner_only', this.bannerOnly);
              this.$store.commit('banner_homepage', this.bannerHomepage);
            },
            mounted: function(){
              this.appViewMode = el.attributes['data-app-view-mode'] ? el.attributes['data-app-view-mode'].value : '';
              this.postFilterContent = el.attributes['data-post-filter-content'] ? el.attributes['data-post-filter-content'].value : this.postFilterContent;
              this.noResultContent = el.attributes['data-no-result-content'] ? el.attributes['data-no-result-content'].value : this.noResultContent;
              this.visitCalloutContent = el.attributes['data-visit-callout-content'] ? el.attributes['data-visit-callout-content'].value : this.visitCalloutContent;
              this.visitCalloutBtn = el.attributes['data-visit-callout-btn'] ? el.attributes['data-visit-callout-btn'].value : this.visitCalloutBtn;
              this.visitCalloutLink = el.attributes['data-visit-callout-link'] ? el.attributes['data-visit-callout-link'].value : this.visitCalloutLink;
              this.searchBannerHeading = el.attributes['data-search-banner-heading'] ? el.attributes['data-search-banner-heading'].value : this.searchBannerHeading;
              this.searchBannerBody = el.attributes['data-search-banner-body'] ? el.attributes['data-search-banner-body'].value : this.searchBannerBody;
              this.searchNoResultsText = el.attributes['data-search-noresults-text'] ? el.attributes['data-search-noresults-text'].value : this.searchNoResultsText;
              this.bannerButtonTitle = el.attributes['data-banner-button-title'] ? el.attributes['data-banner-button-title'].value : this.bannerButtonTitle;

              this.title = el.attributes['data-title'] ? el.attributes['data-title'].value : this.title;
              this.searchUrl = el.attributes['data-search-url'] ? el.attributes['data-search-url'].value : this.searchUrl;
              this.enableMapFeatures = el.attributes['data-enable-map-features'] ? el.attributes['data-enable-map-features'].value == 'true' : this.enableMapFeatures;

              this.$store.commit('app_view_mode', this.appViewMode);
              this.$store.commit('banner_button_title', this.bannerButtonTitle);
              this.$store.commit('search_url', this.searchUrl);
              this.$store.commit('enable_map_features', this.enableMapFeatures);
            }
          });
        }
      })(document.getElementById('locations-tool'));
      
    // Web component version
    Vue.use(vueCustomElement);
    App.store = store;
    Vue.customElement('locations-tool', App);
});


